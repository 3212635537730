<template>
  <feedback-banner-component
    :previousRoute="'student-my-teachers'"
    :forFeedback="'teachers'"
  />

  <div class="bg-white p-4 mt-4 py-5 border-r-10">
    <skeleton
      v-if="feedbacks.isLoading"
      borderRadius="10px"
      height="30rem"
      class="skeleton-dark p-3"
    />
    <template v-else>
      <p>
        At Bright Lingua, our teachers are constantly trained based on feedback
        we receive from students like you. The more detailed feedback you can
        give us on each teacher you take, the better they will become.
      </p>
      <div class="mt-4 d-flex gap-4 flex-column">
        <feedback-write-component
          v-for="item in feedbacks.data.slice(0, countFeedbacks)"
          :key="item"
          :photo="imgPath+item?.teacher_photo"
          :date="item.lesson_start"
          :names="`${item.teacher_name} ${item.teacher_last_name}`"
          :mood="item.send_feedback ? 'edition' : 'new'"
          :handleShowFeedbackModal="
            (e) => toggleLastTeacherModal(true, item, 'edit')
          "
          :handleWriteFeedback="
            (e) => toggleLastTeacherModal(true, item, 'new')
          "
        />
      </div>
      <button
        v-if="feedbacks.data.length > countFeedbacks"
        @click="showMore"
        class="
          btn
          text-primary-1
          border-primary-1
          p-2
          px-5
          d-flex
          align-items-center
          mx-auto
        "
      >
        <i class="pi pi-plus-circle pe-2 fs-5"></i>
        See more
      </button>
    </template>
  </div>
</template>

<script>
import FeedbackBannerComponent from "@/shared/components/Feedback/FeedbackBannerComponent";
import FeedbackWriteComponent from "@/shared/components/Feedback/FeedbackWriteComponent";
import useFeedback from "@/modules/students/composables/MyTeachers/useFeedback";
export default {
  name: "MyTeachersFeedbackView",

  components: {
    FeedbackBannerComponent,
    FeedbackWriteComponent,
  },
  setup() {
    const {
      loadServices,
      feedbacks,
      toggleLastTeacherModal,
      countFeedbacks,
      showMore,
    } = useFeedback();

    loadServices();

    const imgPath = process.env.VUE_APP_API_PROD + '/storage/'

    return {
      showMore,
      feedbacks,
      countFeedbacks,
      toggleLastTeacherModal,
      imgPath
    };
  },
};
</script>

<style></style>
